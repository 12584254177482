import {
  Component,
  OnInit,
  Input,
  EventEmitter,
  Output,
  ElementRef,
  OnChanges,
  SimpleChanges,
  AfterViewInit
} from "@angular/core";
import { MDCIconButtonToggle } from "@material/icon-button";
import { KgUiAriaModel } from "kg-lib-models";
@Component({
  selector: "kg-lib-ui-mdc-icon-button",
  template: `<button
  id="kglibuiiconbutton-{{ kgid }}"
  class="mdc-icon-button"
  [attr.aria-label]="kgaria ? kgaria.label : ''"
  [attr.aria-hidden]="kgaria ? kgaria.hidden : ''"
  [attr.aria-pressed]="kgaria ? kgaria.pressed : ''"
  [disabled]="kgdisabled"
>
  <i class="material-icons mdc-icon-button__icon mdc-icon-button__icon--on">{{
    onicon
  }}</i>
  <i class="material-icons mdc-icon-button__icon">{{ officon }}</i>
</button>
`,
  styles: []
})
export class KgLibUiMdcIconButtonComponent implements OnInit, AfterViewInit {
  @Input() kgid: string;
  @Input() kglabel: string;
  @Input() kgaria: KgUiAriaModel;
  @Output() kgclick = new EventEmitter<boolean>();
  @Input() kgdisabled = false;
  @Input() onicon: string;
  @Input() officon: string;
  @Input() on: boolean = false;

  mdcIconButtonToggle: MDCIconButtonToggle;

  constructor(private elem: ElementRef) {}

  ngOnChanges(changes: SimpleChanges): void {}
  ngOnInit() {}

  ngAfterViewInit(): void {
    let el = document.getElementById(`kglibuiiconbutton-${this.kgid}`);
    this.mdcIconButtonToggle = new MDCIconButtonToggle(el);
    this.mdcIconButtonToggle.on = this.on;
  }

  clicked(event: MouseEvent) {
    this.mdcIconButtonToggle.on = !this.mdcIconButtonToggle.on;
    console.log("iconbuttontoggle state:", this.mdcIconButtonToggle.on);
    this.kgclick.emit(this.mdcIconButtonToggle.on);
  }
}

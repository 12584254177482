export enum KgUiTypographyModes {
  ModuleHeader = 0,
  CardHeader = 1,
  DataListHeader = 2
}

export enum KgUiTypographySize {
  Headline1 = "mdc-typography--headline1",
  Headline2 = "mdc-typography--headline2",
  Headline3 = "mdc-typography--headline3",
  Headline4 = "mdc-typography--headline4",
  Headline5 = "mdc-typography--headline5",
  Headline6 = "mdc-typography--headline6",
  Subtitle1 = "mdc-typography--subtitle1",
  Subtitle2 = "mdc-typography--subtitle2",
  Body1 = "mdc-typography--body1",
  Body2 = "mdc-typography--body2",
  Caption = "mdc-typography--caption",
  Button = "mdc-typography--button",
  Overline = "mdc-typography--overline"
}

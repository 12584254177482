import { Component, OnInit, Input } from "@angular/core";
import { IKgLibUiMaterialIcons } from "kg-lib-models";
import {
  KgUiTypographySize,
  KgUiTypographyModes
} from "./kg-lib-ui-typography-mode.enum";

@Component({
  selector: "kg-lib-ui-typography",
  template: `<div [class]="kgsize">{{ kglabel }}</div>
`,
  styles: [``]
})
export class KgLibUiTypographyComponent implements OnInit {
  @Input() kglabel: string;
  @Input() kgsize: KgUiTypographySize;
  KgUiTypographySize = KgUiTypographySize;

  constructor() {}

  ngOnInit() {}
}
